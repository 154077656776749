var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
// @mui
import { Avatar, Badge, Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, ListSubheader, MenuItem, Skeleton, Stack, Tooltip, Typography, } from '@mui/material';
import { useSnackbar } from 'notistack';
// utils
import { fToNow } from '../../../utils/formatTime';
import { IconButtonAnimate } from '../../../components/animate';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import Scrollbar from '../../../components/scrollbar';
import usePermission from '../../../hooks/usePermission';
import { getAllNotifications, getNotificationsCounts, markOneNotificationAsRead, marlAllNotificationsAsRead, } from '../../../redux/slices/notifications/actions';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
// ---------------------------------------------------------------------
export default function NotificationsPopover() {
    var navigate = useNavigate();
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _a = useSelector(function (state) { return state.notifications; }), fetchedNotification = _a.notifications, unread = _a.unread;
    var _b = useState(null), openPopover = _b[0], setOpenPopover = _b[1];
    var _c = useState([]), notifications = _c[0], setNotifications = _c[1];
    useEffect(function () {
        dispatch(getAllNotifications({ page: 0, limit: 10 }));
        dispatch(getNotificationsCounts());
    }, []);
    useEffect(function () {
        setNotifications(fetchedNotification.docs);
    }, [fetchedNotification]);
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    var handleMarkAllAsRead = function () {
        dispatch(marlAllNotificationsAsRead());
    };
    var handleOnclickNotifItem = function (notification) {
        var docModel = notification.docModel, seen = notification.seen, doc = notification.doc, _id = notification._id;
        if (!seen) {
            dispatch(markOneNotificationAsRead(_id))
                .unwrap()
                .then(function () {
                enqueueSnackbar('This notification is marked as read');
            })
                .catch(function (err) { return enqueueSnackbar(err.message, { variant: 'error' }); });
            handleClosePopover();
        }
        if (docModel === 'Todo') {
            navigate(PATH_DASHBOARD.todos.view(doc), { state: { highlightedTodoId: doc } });
        }
        else {
            navigate(PATH_DASHBOARD.notes.view(doc));
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButtonAnimate, __assign({ color: openPopover ? 'primary' : 'default', onClick: handleOpenPopover, sx: { width: 40, height: 40 } }, { children: _jsx(Badge, __assign({ badgeContent: unread, color: "error" }, { children: _jsx(Iconify, { icon: "eva:bell-fill" }) })) })), _jsxs(MenuPopover, __assign({ open: openPopover, onClose: handleClosePopover, sx: { width: 360, p: 0 } }, { children: [_jsxs(Box, __assign({ sx: { display: 'flex', alignItems: 'center', py: 2, px: 2.5 } }, { children: [_jsxs(Box, __assign({ sx: { flexGrow: 1 } }, { children: [_jsx(Typography, __assign({ variant: "subtitle1" }, { children: "Notifications" })), _jsxs(Typography, __assign({ variant: "body2", sx: { color: 'text.secondary' } }, { children: ["You have ", unread, " unread messages"] }))] })), unread > 0 && (_jsx(Tooltip, __assign({ title: " Mark all as read" }, { children: _jsx(IconButton, __assign({ color: "primary", onClick: handleMarkAllAsRead }, { children: _jsx(Iconify, { icon: "eva:done-all-fill" }) })) })))] })), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Scrollbar, __assign({ sx: { height: { xs: 340, sm: 'auto' } } }, { children: _jsx(List, __assign({ disablePadding: true, subheader: _jsx(ListSubheader, __assign({ disableSticky: true, sx: { py: 1, px: 2.5, typography: 'overline' } }, { children: "New" })) }, { children: notifications.slice(0, 5).map(function (notification) { return (_jsx(NotificationItem, { onClick: function () { return handleOnclickNotifItem(notification); }, notification: notification }, notification._id)); }) })) })), _jsx(Divider, { sx: { borderStyle: 'dashed' } }), _jsx(Box, __assign({ sx: { p: 1 } }, { children: _jsx(Button, __assign({ onClick: function () {
                                handleClosePopover();
                                navigate(PATH_DASHBOARD.Notifications);
                            }, fullWidth: true, disableRipple: true }, { children: "View All" })) }))] }))] }));
}
// ----------------------------------------------------------------------
export function NotificationItem(_a) {
    var notification = _a.notification, onClick = _a.onClick;
    var from = notification.from, message = notification.message, createdAt = notification.createdAt;
    return (_jsxs(ListItemButton, __assign({ sx: {
            py: 1.5,
            px: 2.5,
            mt: '1px',
        }, onClick: onClick }, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, __assign({ sx: { bgcolor: 'background.neutral' } }, { children: from === null || from === void 0 ? void 0 : from.avatar })) }), _jsx(ListItemText, { disableTypography: true, primary: message, secondary: _jsxs(Stack, __assign({ direction: "row", sx: { mt: 0.5, typography: 'caption', color: 'text.disabled' } }, { children: [_jsx(Iconify, { icon: "eva:clock-fill", width: 16, sx: { mr: 0.5 } }), _jsx(Typography, __assign({ variant: "caption" }, { children: fToNow(createdAt) }))] })) })] })));
}
// ----------------------------------------------------------------------
export function NotificationRow(_a) {
    var notification = _a.notification;
    var navigate = useNavigate();
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var _b = usePermission(), isSuperAdmin = _b.isSuperAdmin, hasPermissionEditNotif = _b.hasPermissionEditNotif;
    var isAllowedToEditNotif = isSuperAdmin || hasPermissionEditNotif;
    var _id = notification._id, from = notification.from, message = notification.message, seen = notification.seen, doc = notification.doc, docModel = notification.docModel, createdAt = notification.createdAt;
    var _c = useState(null), openPopover = _c[0], setOpenPopover = _c[1];
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    var markNotificationAsRead = function () {
        dispatch(markOneNotificationAsRead(_id))
            .unwrap()
            .then(function () {
            enqueueSnackbar('This notification is marked as read');
        })
            .catch(function (err) { return enqueueSnackbar(err.message, { variant: 'error' }); });
        handleClosePopover();
    };
    var handleNotificationDetails = function () {
        if (docModel === 'Todo') {
            navigate(PATH_DASHBOARD.todos.view(doc), { state: { highlightedTodoId: doc } });
            if (!seen) {
                markNotificationAsRead();
            }
        }
        else {
            navigate(PATH_DASHBOARD.notes.view(doc));
        }
    };
    return (_jsxs(ListItem, __assign({ sx: {
            py: 1.5,
            px: 2.5,
            mt: '1px',
        } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", sx: {
                    flexGrow: 1,
                } }, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, __assign({ sx: { bgcolor: 'background.neutral' } }, { children: from === null || from === void 0 ? void 0 : from.avatar })) }), _jsx(ListItemText, { disableTypography: true, primary: message, secondary: _jsxs(Stack, __assign({ direction: "row", sx: { mt: 0.5, typography: 'caption', color: 'text.disabled' } }, { children: [_jsx(Iconify, { icon: "eva:clock-fill", width: 16, sx: { mr: 0.5 } }), _jsx(Typography, __assign({ variant: "caption" }, { children: fToNow(createdAt) }))] })) }), _jsx(IconButton, __assign({ size: "large", color: openPopover ? 'inherit' : 'default', onClick: handleOpenPopover }, { children: _jsx(Iconify, { icon: "eva:more-vertical-fill" }) }))] })), _jsxs(MenuPopover, __assign({ open: openPopover, onClose: handleClosePopover, arrow: "right-top" }, { children: [!seen && isAllowedToEditNotif && (_jsxs(MenuItem, __assign({ onClick: markNotificationAsRead }, { children: [_jsx(Iconify, { icon: "eva:checkmark-circle-2-fill" }), "Mark As Read"] }))), _jsxs(MenuItem, __assign({ onClick: handleNotificationDetails }, { children: [_jsx(Iconify, { icon: "carbon:view-filled" }), "More Details"] }))] }))] })));
}
export function NotifSkeleton() {
    return (_jsxs(Box, __assign({ sx: {
            px: 4,
            py: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
        } }, { children: [_jsx(Skeleton, { variant: "circular", width: 40, height: 40 }), _jsxs(Box, __assign({ sx: { flexGrow: 1 } }, { children: [_jsx(Skeleton, { variant: "text", width: "50%", sx: { fontSize: '1rem' } }), _jsx(Skeleton, { variant: "text", width: "20%", sx: { fontSize: '.75rem' } })] }))] })));
}
